enum Colors {
  PRIMARY = '#3FB0E5',
  SECONDARY = '#000000',
  BACKGROUND = '#FAFAFA',
  CARD = '#F9FCFE',
  TEXT = '#8D97A8',
  BORDER = '#F3F3F3',
  NOTIFICATION = '#0E1501',
  WHITE = '#ffffff',
  BLACK = '#000000',
  INPUT_BG = '#EFF3FA',
  INPUT_PLACEHOLDER_COLOR = '#8D97A8',
  CHECKBOX = '#707070',
  LIGHT_PRIMARY = '#3FB0E51A',
  CARD_BG = '#3FB0E50D',
  TAB_BAR_ITEM_BG = '#3FB0E533',
}

export default Colors;
