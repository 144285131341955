import React from "react";
import { Link } from "react-router-dom";

import logo from "../../../assets/images/logo.svg";
import { PUBLIC } from "../../../routes/routes";

const Footer: React.FC = () => {
  return (
      <section className="footer-con">
          <div className="container">
              <div className="row">
                  <div className="col-sm-4">
                      <div className="f-logo">
                          <img src={logo} alt={'LOGO'} className="img-fluid" />
                      </div>
                  </div>
                  <div className="col-sm-8">
                      <div className="f-tline"></div>
                  </div>
              </div>

              <div className="row mt-4">
                  <div className="col-sm-6">
                      <div className="f-left">
                          <p>
                              We can help you find the best car. Check our
                              reviews, compare models and find cars for sale.
                          </p>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className="f-right-row">
                          <Link
                              className="f-mail"
                              to="mailto:connect@carsinindia.in"
                          >
                              connect@carsinindia.in
                          </Link>
                          <Link className="f-phone" to="tel:+91 9847124488">
                              +91 9847124488
                          </Link>
                      </div>
                      <div className="f-right-row">
                          <div className="row">
                              <div className="col-sm-8">
                                  <b>Quick menu</b>
                              </div>
                              <div className="col-sm-4">
                                  <ul>
                                      <li>
                                          <Link to={PUBLIC.BASE_PATH+PUBLIC.PAGES.TERMS_AND_CONDITIONS}>Terms and Conditions</Link>
                                      </li>
                                      <li>
                                          <Link to={PUBLIC.BASE_PATH + PUBLIC.PAGES.PRIVACY_POLICY}>Privacy Policy</Link>
                                      </li>
                                      <li>
                                          <Link to={PUBLIC.BASE_PATH + PUBLIC.PAGES.ABOUT_US}>About Us</Link>
                                      </li>
                                      <li>
                                          <Link to="#">Services</Link>
                                      </li>
                                      <li>
                                          <Link to="#">Auction</Link>
                                      </li>
                                      <li>
                                          <Link to="#">Rent a Car</Link>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div className="f-right-row border-0">
                          <div className="row">
                              <div className="col-sm-8">
                                  <b>Follow</b>
                              </div>
                              <div className="col-sm-4">
                                  <ul>
                                      <li>
                                          <Link to="#">Facebook</Link>
                                      </li>
                                      <li>
                                          <Link to="#">Instagram</Link>
                                      </li>
                                      <li>
                                          <Link to="#">YouTube</Link>
                                      </li>
                                      <li>
                                          <Link to="#">LinkedIn</Link>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="container fbtm-top">
              <div className="row">
                  <div className="col-sm-9">
                      © 2022 Cars In India All Rights Reserved
                  </div>
                  <div className="col-sm-3">
                      <Link to="#">Privacy Policy</Link>{' '}
                      <Link to="#">Condition Apply</Link>
                  </div>
              </div>
          </div>
      </section>
  );
};

export default Footer;
