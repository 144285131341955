import axios from "axios";

import { logout } from "../store/slices/user";
import { Store } from "@reduxjs/toolkit";

const service = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 60000,
});

const setupInterceptors = (store: Store) => {
  service.interceptors.request.use(
    async (config: any) => {
      const token = await localStorage.getItem("token");
      config.headers.Accept = "application/json";
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error: any) => {
      Promise.reject(error);
    }
  );

  service.interceptors.response.use(
    (response: any) => {
      return response;
    },
    async (error: any) => {
      if (error?.response?.status === 401) {
        store.dispatch(
          logout({
            isTokenExpired: true,
          }) as any
        );
        return Promise.reject({
          ...error.response,
          message: "Unauthorized. Please login to continue.",
        });
      } else {
        return Promise.reject(error);
      }
    }
  );
};

export { setupInterceptors };
export default service;
