import React, { useEffect } from "react";
import { toast, ToastContainer } from "react-toastify";

import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./assets/css/custom.css";
import "./assets/css/custom-carousel.css";
import "react-toastify/dist/ReactToastify.css";

import MainRoute from "./routes";
import { store } from "./store/store";
import { checkAuthorization } from "./store/slices/user";

const App: React.FC = () => {
  useEffect(() => {
    store.dispatch(checkAuthorization());
  }, []);
  return (
    <>
      <MainRoute />
      <ToastContainer
        closeOnClick
        style={{ width: "auto", minWidth: "340px", maxWidth: "450px" }}
        position={toast.POSITION.BOTTOM_RIGHT}
        bodyStyle={{ color: "#756f86" }}
      />
    </>
  );
};

export default App;
