import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import moment from "moment";
import { toast } from "react-toastify";

import service from "../../utils/service";
import { getFcmToken, registerToken } from "../../utils/firebase";
import { getAuctionSpec, setBaseCategories, setLandingShowed } from "./other";

import { RootState } from "../store";
import API from "../../config/api";
import { useNavigate } from "react-router-dom";
import { PRIVATE } from "../../routes/routes";

type PickerItem = {
  label: string;
  value: string;
};

interface UserState {
  isAuthenticated: boolean;
  isAppInitialized: boolean;
  user: any;
  error: string;
  loading: boolean;
  errors: any;
  profileLoading: boolean;
}
const initialState: UserState = {
  isAuthenticated: false,
  isAppInitialized: false,
  user: {},
  error: "",
  loading: false,
  errors: {},
  profileLoading: false,
};
export const login = createAsyncThunk(
  "user/login",
  async (params: any, { dispatch }) => {
    try {
      const response = await service.post(API.LOGIN, params);
      if (response?.data?.status_code === 200 && response.data.data) {
        dispatch(getAuctionSpec());
        let tempAuctionTypes: PickerItem[] = [];
        let tempUserCategory: PickerItem[] = [];
        if (response.data?.data?.baseCategories) {
          for (const [key, value] of Object.entries(
            response.data?.data?.baseCategories || {}
          )) {
            tempAuctionTypes.push({
              label: value as string,
              value: key as string,
            });
          }
          dispatch(setBaseCategories(tempAuctionTypes));
          delete response.data?.data?.baseCategories;
        }
        if (response.data?.data?.user_category) {
          for (const [key, value] of Object.entries(
            response.data?.data?.user_category || {}
          )) {
            tempUserCategory.push({
              label: value as string,
              value: key as string,
            });
          }
        }
        await getFcmToken();
        await localStorage.setItem("token", response.data?.token || "");
        await localStorage.setItem(
          "user",
          JSON.stringify({
            ...response.data?.data,
            user_category: tempUserCategory,
          })
        );

        registerToken();
        // onAuthSuccess();
        return {
          user: {
            ...response.data.data,
            user_category: tempUserCategory,
          },
        };
      } else {
        throw new Error(response?.data?.message || "Something went wrong");
      }
    } catch (err: any) {
      throw new Error(err.message || "Something went wrong");
    }
  }
);

export const verifyOtp = createAsyncThunk(
  "user/verifyOtp",
  async (params: any, { dispatch }) => {
    try {
      const response = await service.post(API.VERIFY_OTP, params);
      if (response?.data?.status_code === 200 && response.data) {
        toast.success(response.data?.message);
        toast.success("Please Change the password to continue.");
        let tempAuctionTypes: PickerItem[] = [];
        let tempUserCategory: PickerItem[] = [];
        if (response.data?.data?.baseCategories) {
          for (const [key, value] of Object.entries(
            response.data?.data?.baseCategories || {}
          )) {
            tempAuctionTypes.push({
              label: value as string,
              value: key as string,
            });
          }
          //   dispatch(setBaseCategories(tempAuctionTypes));
          delete response.data?.data?.baseCategories;
        }
        if (response.data?.data?.user_category) {
          for (const [key, value] of Object.entries(
            response.data?.data?.user_category || {}
          )) {
            tempUserCategory.push({
              label: value as string,
              value: key as string,
            });
          }
        }
        // await getFcmToken();
        await localStorage.setItem("token", response.data?.token || "");
        await localStorage.setItem(
          "user",
          JSON.stringify({
            ...response.data?.data,
            user_category: tempUserCategory,
          })
        );
        // registerToken();
        // onAuthSuccess();
        return {
          user: {
            ...response.data.data,
            user_category: tempUserCategory,
          },
        };
      } else {
        throw new Error(response?.data?.message || "Something went wrong");
      }
    } catch (err: any) {
      toast.error(err?.message || "Something went wrong");
      throw new Error(err?.message || "Something went wrong");
    }
  }
);

export const register = createAsyncThunk(
  "user/register",
  async (values: any, { rejectWithValue }) => {
    try {
      let formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("confirm_password", values.confirm_password);
      formData.append("mobile_no", values.mobile_no);
      formData.append(
        "dob",
        values.dob ? moment(values.dob).format("YYYY-MM-DD") : ""
      );
      formData.append("city", values.city);
      formData.append("address_1", values.address_1);
      formData.append("address_2", values.address_2);
      formData.append("pancard_no", values.pancard_no);
      // if (values.image && values.image.assets?.length > 0) {
      //   formData.append('image', {
      //     uri: values.image.assets[0].uri,
      //     type: values.image.assets[0].type,
      //     name: values.image.assets[0].fileName,
      //   });
      // }
      const response = await service.post(API.REGISTER_AUCTION_USER, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        transformRequest: (formdata) => formdata,
      });
      if (response?.data?.status_code === 200 && response.data?.data) {
        await localStorage.setItem("token", response.data?.token || "");
        // navigate(ROUTER.OTP as keyof RootStackParamList, {
        //   isVerifyMobile: true,
        //   username: values.mobile_no,
        // });
        return {
          user: response.data.data,
        };
      } else if (response.data?.status_code === 400 && response.data?.errors) {
        return rejectWithValue(response?.data?.errors || {});
      } else {
        throw new Error(response.data?.message || "Something went wrong");
      }
    } catch (err: any) {
      throw new Error(err?.message || "Something went wrong");
    }
  }
);

export const logout = createAsyncThunk(
  "user/logout",
  async (
    params: {
      isLaunch?: boolean;
      avoidRedirect?: boolean;
      isTokenExpired?: boolean;
    },
    { dispatch }
  ) => {
    try {
      if (params.isLaunch) {
        await localStorage.clear();
        // onLogout(true);
      } else if (params.isTokenExpired) {
        await localStorage.clear();
        // onLogout(false);
      } else {
        await service.get(API.LOGOUT, {});
        await localStorage.clear();
        // onLogout();
      }
      dispatch(setLandingShowed(false));
      return;
    } catch (err: any) {
      throw err;
    }
  }
);

export const getProfile = createAsyncThunk(
  "user/getProfile",
  async (_, { rejectWithValue, getState }) => {
    try {
      const response = await service.get(API.GET_PROFILE);
      if (response?.data?.status_code === 200 && response.data.data) {
        const user = (getState() as RootState).user?.user;
        await localStorage.setItem(
          "user",
          JSON.stringify({
            ...user,
            id: response.data.data.id,
            image: response.data.data.image,
            is_auction_user: response.data.data.is_auction_user || 0,
            name: response.data.data.name,
            profile: response.data?.data,
          })
        );
        return (
          {
            ...user,
            id: response.data.data.id,
            image: response.data.data.image,
            is_auction_user: response.data.data.is_auction_user || 0,
            name: response.data.data.name,
            profile: response.data?.data,
          } || {}
        );
      } else {
        rejectWithValue(
          (response.data?.message as string) || "Something went wrong"
        );
      }
    } catch (err: any) {
      return rejectWithValue(err.message || "Something went wrong");
    }
  }
);

export const checkAuthorization = createAsyncThunk(
  "user/checkAuthorization",
  (_, { dispatch, rejectWithValue }) => {
    try {
      // const tmpAT = localStorage.getItem("auth-access-token") ?? "";
      // const tmpRT = localStorage.getItem("auth-refresh-token") ?? "";
      const tmpAT = localStorage.getItem("token") ?? "";
      const tmpUserStr = localStorage.getItem("user") ?? "";
      if (tmpAT && tmpUserStr) {
        dispatch(getProfile());
        let usr = JSON.parse(tmpUserStr);
        return usr;
      } else {
        return rejectWithValue("No token found");
      }
    } catch (err: any) {
      return rejectWithValue(err.message || "Something went wrong");
    }
  }
);

export const registerBasicUser = createAsyncThunk(
  "user/registerBasicUser",
  async (values: any, { rejectWithValue }) => {
    try {
      const params = {
        name: values?.name,
        email: values?.email,
        password: values?.password,
        confirm_password: values?.confirm_password,
        mobile_no: values?.mobile_no,
      };

      const response = await service.post(API.REGISTER_BASIC_USER, params);
      if (response?.data?.status_code === 200 && response.data?.data) {
        await localStorage.setItem("token", response.data?.token || "");
        // navigate(ROUTER.OTP as keyof RootStackParamList, {
        //   isVerifyMobile: true,
        //   username: values.mobile_no,
        // });
        return {
          user: response.data.data,
        };
      } else if (response.data?.status_code === 400 && response.data?.errors) {
        return rejectWithValue(response?.data?.errors || {});
      } else {
        throw new Error(response.data?.message || "Something went wrong");
      }
    } catch (err: any) {
      throw new Error(err?.message || "Something went wrong");
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    saveCurrentUser(state, action: PayloadAction<any>) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },
    clearError(state) {
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkAuthorization.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.isAppInitialized = true;
      state.user = action.payload;
    });
    builder.addCase(checkAuthorization.rejected, (state) => {
      state.isAuthenticated = false;
      state.isAppInitialized = true;
    });
    builder.addCase(checkAuthorization.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(login.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload?.user;
      state.error = "";
      state.errors = {};
    });
    builder.addCase(login.rejected, (state, action: any) => {
      state.error = action.error?.message || "something went wrong";
      state.isAuthenticated = false;
      state.loading = false;
      state.user = {};
    });
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload?.user;
      state.error = "";
    });
    builder.addCase(verifyOtp.rejected, (state, action: any) => {
      state.error = action.error.message || "something went wrong";
      state.isAuthenticated = false;
      state.loading = false;
      state.user = {};
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.isAuthenticated = false;
      state.user = {};
    });
    builder.addCase(logout.rejected, (state) => {
      state.error = "something went wrong";
    });

    builder.addCase(register.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(register.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload?.user;
      state.error = "";
      state.errors = {};
    });
    builder.addCase(register.rejected, (state, action: any) => {
      if (action.payload) {
        state.errors = action.payload || {};
      } else {
        state.error = action.error?.message || "something went wrong";
      }
      state.isAuthenticated = false;
      state.loading = false;
      state.user = {};
    });

    builder.addCase(getProfile.pending, (state) => {
      state.profileLoading = true;
      state.error = "";
    });
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.profileLoading = false;
      state.user = action.payload;
      state.error = "";
      state.errors = {};
    });
    builder.addCase(getProfile.rejected, (state, action) => {
      state.profileLoading = false;
      state.error =
        (action.error.message as string) || (action.payload as string);
    });

    builder.addCase(registerBasicUser.pending, (state) => {
      state.loading = true;
      state.error = "";
    });
    builder.addCase(registerBasicUser.fulfilled, (state, action) => {
      state.isAuthenticated = true;
      state.loading = false;
      state.user = action.payload?.user;
      state.error = "";
      state.errors = {};
    });
    builder.addCase(registerBasicUser.rejected, (state, action: any) => {
      if (action.payload) {
        state.errors = action.payload || {};
      } else {
        state.error = action.error?.message || "something went wrong";
      }
      state.isAuthenticated = false;
      state.loading = false;
      state.user = {};
    });
  },
});

export const { saveCurrentUser, clearError } = userSlice.actions;
export default userSlice.reducer;
