import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import API from '../../config/api';
import service from '../../utils/service';

type PickerItem = {
  label: string;
  value: string;
};

interface OtherState {
  selectedAuction: string | undefined;
  updateAuctionDetails: boolean;
  landingShowed: boolean;
  updateMyBids: boolean;
  baseCategories: PickerItem[];
  specLoading: boolean;
  auctionSpecs: any[];
  vehicleSpecs: any[];
  error: string;
}

const initialState: OtherState = {
  selectedAuction: undefined,
  updateAuctionDetails: false,
  landingShowed: false,
  updateMyBids: false,
  baseCategories: [],
  specLoading: false,
  auctionSpecs: [],
  vehicleSpecs: [],
  error: '',
};

export const getAuctionSpec = createAsyncThunk(
  'other/getAuctionSpec',
  async (_, {rejectWithValue}) => {
    try {
      const response = await service.get(API.AUCTION_SPEC);
      if (response?.data?.status_code === 200) {
        return response.data?.data || {};
      } else {
        rejectWithValue(
          (response.data?.message as string) || 'Something went wrong',
        );
      }
    } catch (err: any) {
      return rejectWithValue(err.message || 'Something went wrong');
    }
  },
);

const otherSlice = createSlice({
  name: 'other',
  initialState,
  reducers: {
    setSelectedAuction(state, action) {
      state.selectedAuction = action.payload;
    },
    setUpdateAuctionDetails(state, action) {
      state.updateAuctionDetails = action.payload;
    },
    setLandingShowed(state, action) {
      state.landingShowed = action.payload;
    },
    setUpdateMyBids(state, action) {
      state.updateMyBids = action.payload;
    },
    setBaseCategories(state, action) {
      state.baseCategories = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(getAuctionSpec.pending, state => {
      state.specLoading = true;
      state.error = '';
    });
    builder.addCase(getAuctionSpec.fulfilled, (state, action) => {
      state.specLoading = false;
      state.vehicleSpecs = action.payload?.vehicleDetails || [];
      state.auctionSpecs = action.payload?.auctionDetails || [];
      state.error = '';
    });
    builder.addCase(getAuctionSpec.rejected, (state, action: any) => {
      state.error = action.error?.message || 'something went wrong';
      state.specLoading = false;
      state.vehicleSpecs = [];
      state.auctionSpecs = [];
    });
  },
});

export const {
  setSelectedAuction,
  setUpdateAuctionDetails,
  setLandingShowed,
  setUpdateMyBids,
  setBaseCategories,
} = otherSlice.actions;
export default otherSlice.reducer;
