import React from 'react';
import { Link } from 'react-router-dom';
import { PRIVATE } from '../../../../routes/routes';
import useMyCarsList from './useMyCarsList';
import carImg from '../../../../assets/images/cars/Rectangle 155.jpg';

const MyCarsListing = () => {
    const { myCarList } = useMyCarsList();
    return (
        <>
            <section className="h-bnr inner-black-bnr">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="bnr-small-title">List Cars</div>
                            <div className="bnr-big-title">List Cars</div>
                        </div>
                        <div className="col-sm-8"></div>
                    </div>
                </div>
            </section>
            <section className="inner-page gin-t">
                <div className="container">
                    <div className="list-wrap">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row">
                                    {myCarList?.length === 0 && (
                                        <h1>No Cars Found for the Search.</h1>
                                    )}
                                    {myCarList?.length > 0 &&
                                        myCarList?.map(
                                            (item: any, index: number) => (
                                                <div className="col-sm-4" key={index}>
                                                    <Link
                                                        to={`${PRIVATE.BASE_PATH}/${PRIVATE.PAGES.MY_CAR_DETAILS}`.replace(
                                                            ':id',
                                                            item?.id
                                                        )}
                                                    >
                                                        <div className="list-bx mb-4">
                                                            <div className="list-bx-img">
                                                                <img
                                                                    src={
                                                                        item?.vehicle_image &&
                                                                        !item.vehicle_image.startsWith(
                                                                            'https://'
                                                                        )
                                                                            ? 'https://dev.admin.carsinindia.in/' +
                                                                              item?.vehicle_image
                                                                            : carImg
                                                                    }
                                                                    className="img-fluid w-100"
                                                                    alt="car"
                                                                    style={{
                                                                        width: '306px',
                                                                        height: '201px',
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="list-bx-con">
                                                                <span className="list-bx-status">
                                                                    Used
                                                                </span>
                                                                <div className="list-bx-title mt-2 txt_overflow">
                                                                    {`${
                                                                        item?.title ||
                                                                        ''
                                                                    } ${
                                                                        item?.vehicle_model ||
                                                                        ''
                                                                    }` || 'N/A'}
                                                                </div>
                                                                <div className="list-bx-price mt-2">
                                                                    {' '}
                                                                    {`₹ ${
                                                                        item?.price ||
                                                                        'N/A'
                                                                    }`}
                                                                </div>
                                                                <div className="list-bx-dtls mt-3">
                                                                    <ul>
                                                                        <li>
                                                                            Kilometers
                                                                            <span>
                                                                                {item?.kilometers ||
                                                                                    'N/A'}{' '}
                                                                                Km
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            Fuel
                                                                            <span>
                                                                                {item?.vehicle_fuel ||
                                                                                    'N/A'}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            Manufacturing
                                                                            Year
                                                                            <span>
                                                                                {item?.vehicle_year ||
                                                                                    'N/A'}
                                                                            </span>
                                                                        </li>
                                                                        <li>
                                                                            Location
                                                                            <span>
                                                                                {item?.location ||
                                                                                    'N/A'}
                                                                            </span>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        )}
                                </div>
                                {/* <Pagination pageInfo={pageInfo} /> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MyCarsListing;
