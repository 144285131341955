import React from 'react';
import { Accordion } from 'react-bootstrap';

const More = ({ price }: any) => {
    return (
        <div className="faq-bx mb-4">
            <Accordion className="accordion" id="faqAccordion">
                <Accordion.Item eventKey={'1'}>
                    <Accordion.Header>
                        What is the selling price of the vehicle?
                    </Accordion.Header>
                    <Accordion.Body>
                        The selling price is ₹ {price ? price : 170000}.
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default More;
