import React from "react";
import { Link, useLocation } from "react-router-dom";

import logoWhite from "../../../assets/images/logo-w.svg";
import logo from "../../../assets/images/logo.svg";
import Colors from "../../../config/colors";
import { AUTH, PRIVATE, PUBLIC } from "../../../routes/routes";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { logout } from "../../../store/slices/user";

const Header: React.FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isAuthenticated } = useAppSelector((state: any) => state.user);
  const { name } = useAppSelector((state: any) => state?.user?.user);

  const getHeaderTheme = () => {
    if (location.pathname.includes(AUTH.BASE_PATH)) {
      return "light";
    }
    if (location.pathname.includes(PUBLIC.PAGES.CONTACT_US)) {
      return "light";
    }
    return "dark";
  };

  const showHeaderNav = () => {
    let d = document.getElementById("navbarCollapse");
    d?.classList.toggle("show");
  };

  const h = (n: string) => {
    let elmntToView = document.getElementById(n);
    elmntToView?.scrollIntoView();
  };

  function toggleDropdown() {
    document
      ?.getElementById("navbarDarkDropdownMenuLink")
      ?.classList.toggle("show");
  }

  return (
    <header>
      <nav
        className={`navbar navbar-expand-sm w-head fixed-top ${
          getHeaderTheme() === "light"
            ? "navbar-light bg-white shadow-sm"
            : "navbar-dark bg-dark"
        } `}
      >
        <div className='container'>
          <Link
            className='navbar-brand'
            to={PUBLIC.BASE_PATH + PUBLIC.PAGES.LANDING}
          >
            <img
              src={getHeaderTheme() === "light" ? logoWhite : logo}
              width='150'
            />
          </Link>

          <button
            className='navbar-toggler'
            type='button'
            onClick={showHeaderNav}
            data-bs-toggle='collapse'
            data-bs-target='#navbarCollapse'
            aria-controls='navbarCollapse'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarCollapse'>
            <ul className='navbar-nav ms-auto mb-2 mb-md-0'>
              <li className='nav-item'>
                <Link
                  to={PUBLIC.BASE_PATH + PUBLIC.PAGES.LANDING}
                  className={`nav-link ${
                    location.pathname.includes(PUBLIC.PAGES.LANDING)
                      ? "active"
                      : ""
                  } `}
                  aria-current='page'
                >
                  Home
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  // to={PUBLIC.BASE_PATH + PUBLIC.PAGES.ABOUT_US}
                  to={'#'}
                  onClick={() => h('about_page')}
                  className={`nav-link ${
                    location.pathname.includes(PUBLIC.PAGES.ABOUT_US)
                      ? "active"
                      : ""
                  }`}
                >
                  About Us
                </Link>
              </li>
              <li className='nav-item dropdown'>
                <Link
                  className={`nav-link dropdown-toggle  ${
                    location.pathname?.includes("services") ? "active" : ""
                  }`}
                  to='#'
                  id='navbarDropdown'
                  role='button'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                  aria-label='navbarDarkDropdownMenuLink'
                  onClick={toggleDropdown}
                  onBlur={toggleDropdown}
                >
                  Services
                </Link>
                <ul
                  className='dropdown-menu dropdown-menu-dark'
                  aria-labelledby='navbarDarkDropdownMenuLink'
                >
                  <li>
                    <Link
                      className='dropdown-item'
                      to={PUBLIC.BASE_PATH + PUBLIC.PAGES.INSURANCE}
                    >
                      INSURANCE
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to={
                        PUBLIC.BASE_PATH + PUBLIC.PAGES.VEHICLEVALUATIONSERVICES
                      }
                    >
                      VEHICLE VALUATION SERVICES
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to={PUBLIC.BASE_PATH + PUBLIC.PAGES.FINANCE}
                    >
                      FINANCE
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to={PUBLIC.BASE_PATH + PUBLIC.PAGES.BUYER_GUIDANCE}
                    >
                      BUYER GUIDANCE
                    </Link>
                  </li>
                  <li>
                    <Link
                      className='dropdown-item'
                      to={PUBLIC.BASE_PATH + PUBLIC.PAGES.VEHICLE_DOCUMENTED}
                    >
                      VEHICLE DOCUMENTAL REALATED SERVICES
                    </Link>
                  </li>
                </ul>
              </li>
              <li className='nav-item'>
                <Link
                  to={PRIVATE.BASE_PATH + "/" + PRIVATE.PAGES.ADD_VEHICLE}
                  className={`nav-link ${
                    location.pathname.includes(PRIVATE.PAGES.ADD_VEHICLE)
                      ? "active"
                      : ""
                  }`}
                >
                  Sell
                </Link>
              </li>
              <li className='nav-item'>
                <Link
                  to={PUBLIC.BASE_PATH + PUBLIC.PAGES.USED_CARS}
                  className={`nav-link ${
                    location.pathname.includes(PUBLIC.PAGES.USED_CARS)
                      ? "active"
                      : ""
                  }`}
                >
                  Used Cars
                </Link>
              </li>
              <li className='nav-item pe-3'>
                <Link
                  to={PUBLIC.BASE_PATH + PUBLIC.PAGES.CONTACT_US}
                  className={`nav-link ${
                    location.pathname.includes(PUBLIC.PAGES.CONTACT_US)
                      ? "active"
                      : ""
                  }`}
                >
                  Contact Us
                </Link>
              </li>
              <li className='nav-item pe-3'>
                {isAuthenticated ? (
                  // <!-- Example single danger button -->
                  <>
                    <div className='btn-group'>
                      <button
                        type='button'
                        style={{
                          backgroundColor: Colors.PRIMARY,
                        }}
                        className='btn btn-info text-white btn btn-lg logreg-btn dropdown-toggle'
                        data-toggle='dropdown'
                        aria-haspopup='true'
                        aria-expanded='false'
                        onClick={() =>
                          document
                            .getElementById("header_dropdown")
                            ?.classList.toggle("show")
                        }
                      >
                        { name }
                      </button>
                    </div>
                    <div className='dropdown-menu' id={"header_dropdown"}>
                      <Link
                        className='dropdown-item'
                        to={
                          PRIVATE.BASE_PATH + "/" + PRIVATE.PAGES.EDIT_PROFILE
                        }
                        onBlur={() =>
                          document
                            .getElementById("header_dropdown")
                            ?.classList.remove("show")
                        }
                      >
                        Profile
                      </Link>
                      <Link
                        className='dropdown-item'
                        to={PRIVATE.BASE_PATH + "/" + PRIVATE.PAGES.MY_CARS}
                        onBlur={() =>
                          document
                            .getElementById("header_dropdown")
                            ?.classList.remove("show")
                        }
                      >
                        My Cars
                      </Link>
                      <Link
                        className='dropdown-item'
                        to='#'
                        onClick={() =>
                          dispatch(
                            logout({
                              isLaunch: false,
                              avoidRedirect: false,
                              isTokenExpired: false,
                            })
                          )
                        }
                        onBlur={() =>
                          document
                            .getElementById("header_dropdown")
                            ?.classList.remove("show")
                        }
                      >
                        Logout
                      </Link>
                    </div>
                  </>
                ) : (
                  <Link
                    to={AUTH.BASE_PATH + "/" + AUTH.PAGES.LOGIN}
                    className='btn btn-lg logreg-btn'
                  >
                    Login / Register
                  </Link>
                )}
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
