import React, { useEffect } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Footer from "../components/common/footer";
import Header from "../components/common/header";
import { AUTH } from "../routes/routes";
import { useAppSelector } from "../store/hooks";

const PrivateLayout: React.FC = () => {
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state: any) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location?.pathname]);

  if (!isAuthenticated) {
    return <Navigate to={`${AUTH.BASE_PATH}`} />;
  }
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
};

export default PrivateLayout;
