import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import API from '../../../../config/api';
import service from '../../../../utils/service';

const useMyCarsList = () => {
    const user = useSelector((state: any) => state.user.user);
    console.log(user)
    const [myCarList, setMyCarList] = useState([]);
    const { id } = user;
    useEffect(() => {
        getMyCars();
    }, []);

    const getMyCars = async () => {
        try {
            const { data } = await service.post(API.GET_MY_CARS, {
                user_id: id,
            });
            // console.log(data);
            if (data.status_code === 200) {
                setMyCarList(data.data.result);
                // toast.success(data?.status);
            } else if (data.errors) {
                // toast.error(data.error.message);
                return;
            }
        } catch (err: any) {
            toast.error(err || 'Something went wrong.');
        }
    };
    return {
        myCarList,
    };
};
export default useMyCarsList;
