import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import API from '../../config/api';
import service from '../../utils/service';

interface propInterface {
    searchFilters: {
        additionalFeatures: Array<any>;
        brand: Array<any>;
        budget: any;
        ownership: any;
        states: Array<any>;
        transmission: any;
    };
    result: {
        data: any;
    };
}
interface initInterface {
    loading: boolean;
    value: propInterface;
    error: any;
}
const searchFilters = {
    additionalFeatures: [],
    brand: [],
    budget: {},
    ownership: {},
    states: [],
    transmission: [],
};
const result = {
    data: {},
};
const initialState: initInterface = {
    loading: false,
    value: { searchFilters, result },
    error: '',
};

export const list_all_vehicle = createAsyncThunk(
    'buySell/list-vehicles-for-sale',
    async (params: any, { rejectWithValue }) => {
        try {
            const response = await service.post(API.GET_USED_CARS, params);
            if (response?.data?.status_code === 200) {
                return response.data?.data || {};
            } else {
                return rejectWithValue(
                    (response.data?.message as string) || 'Something went wrong'
                );
            }
        } catch (err: any) {
            return rejectWithValue(err.message || 'Something went wrong');
        }
    }
);

const list_all_vehicle_slice = createSlice({
    name: 'list_all_vehicle',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(list_all_vehicle.pending, (state) => {
            state.loading = true;
            state.error = '';
        });
        builder.addCase(list_all_vehicle.fulfilled, (state, action) => {
            state.loading = false;
            state.value = action.payload;
        });
        builder.addCase(list_all_vehicle.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error;
        });
    },
});

export const searchFilterList = (state: any) =>
    state.list_used_cars?.value?.searchFilters;

export const usedCarsList = (state: any) =>
    state.list_used_cars?.value?.result?.data;

export const usedCarPageInfo = (state: any) => {
    let pageInfo = {
        currentPage: state.list_used_cars?.value?.result?.current_page,
        totalPages: state.list_used_cars?.value?.result?.total,
        perPage: state.list_used_cars?.value?.result?.per_page,
        totalItems: state.list_used_cars?.value?.result?.total,
    };
    return pageInfo;
};

export default list_all_vehicle_slice.reducer;
