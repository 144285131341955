export const PUBLIC = {
  BASE_PATH: '/',
  PAGES: {
    NEWLANDING: 'home',
    LANDING: 'home1',
    ABOUT_US: 'about-us',
    SERVICES: 'services',
    CONTACT_US: 'contact-us',
    CAR_BUY: 'car_buy',
    // services
    INSURANCE: 'services/insurance',
    VEHICLEVALUATIONSERVICES: 'services/vehicleValuationServices',
    FINANCE: 'services/finance',
    BUYER_GUIDANCE: 'services/buyer_guidance',
    VEHICLE_DOCUMENTED: 'services/vehicle_documented',

    USED_CARS: 'used-cars',
    TERMS_AND_CONDITIONS: 'terms-and-conditions',
    PRIVACY_POLICY: 'privacy-policy',
  },
};

// AUTH
export const AUTH = {
  BASE_PATH: "/auth",
  PAGES: {
    LOGIN: "login",
    REGISTER: "register",
    FORGOT_PASSWORD: "forgot-password",
    RESET_PASSWORD: "reset-password",
    PASSWORD: "password",
    OTP: "otp",
  },
};

// AUTH
export const PRIVATE = {
  BASE_PATH: "/user",
  PAGES: {
    MY_BID: "my-bid",
    AUCTION_LIST: "auctions",
    AUCTION_DETAILS: "auctions/:id",
    AUCTION_CHECKLIST: "auctions/:id/checklist",
    AUCTION_PRICING: "auctions/pricing",
    
    // Buy and Sell
    MY_CARS: "my-cars",
    MY_CAR_DETAILS: "my-cars/:id",
    ADD_VEHICLE: "add-vehicle",
    USED_CAR_DETAILS: 'car/car_details/:id',
    ENQUIRY: "enquiry/:vehicle_id",

    // Profile
    EDIT_PROFILE: "edit-profile",
  },
};

export const ERROR = {
  ERROR_403: "/403",
  CATCH_ALL: "*",
};
