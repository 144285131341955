enum API {
    PRIVACY_POLICY = 'privacy_policy',
    TERMS = 'terms_and_conditions',
    HELP = 'enquiry',
    LOGIN = 'login',
    LOGOUT = 'logout',
    REGISTER_AUCTION_USER = 'registerAuctionUser',
    SEND_OTP = 'sendOtp',
    VERIFY_OTP = 'verifyOtp',
    DOCUMENTS_UPLOAD = 'uploadDoc',
    CHECK_USER = 'checkEmailOrPhone',
    LIST_USER_CATEGORIES = 'listCategories',
    UPDATE_USER_CATEGORY = 'updateCategoryForUser',
    GET_FILTERS = 'filters',
    GET_AUCTIONS = 'listAuctions',
    AUCTION_DETAILS = 'AuctionDetails',
    BID_AUCTION = 'bidAuction',
    LIST_BIDS = 'listUserBidAuction',
    PROFILE = 'profile',
    EDIT_PROFILE = 'buySell/update-user-profile',
    CHANGE_PASSWORD = 'changePassword',
    RESET_PASSWORD = 'resetPassword',
    NOTIFICATIONS = 'notifications',
    POST_FCM = 'addDeviceToken',
    AUCTION_SPEC = 'auctionspec',
    CONTACT_US = 'buySell/send-contactus-email',

    GET_MY_CARS = 'buySell/get-user-vehicles',
    MARK_AS_SOLD = 'buySell/mark-vehicle-as-sold',
    GET_USED_CARS = 'buySell/list-vehicles-for-sale',
    GET_USED_CAR_DETAIL = 'buySell/vehicle-details',
    GET_VEHICLE_RELATED_DATA = 'buySell/get-vehicle-related-data',
    CREATE_VEHICLE = 'buySell/createVehicle',
    GET_MODEL = 'buySell/get-vehicle-models',

    GET_PROFILE = 'buySell/profile',
    REGISTER_BASIC_USER = 'registerBasicUser',

    CREATE_ENQUIRY = 'buySell/contact-seller-via-email',
    LIST_ALL_VEHICLE = 'buySell/listAllVehicle',

    GET_SELLER_CONTACT = 'buySell/get-vehicle-seller-details',
    GET_RELATED_VEHICLES = 'buySell/search-related-vehicles'
}

const publicEndpoints = [
  API.PRIVACY_POLICY,
  API.TERMS,
  API.HELP,
  API.LOGIN,
  API.LOGOUT,
  API.REGISTER_AUCTION_USER,
  API.SEND_OTP,
  API.VERIFY_OTP,
  API.CHECK_USER,
];

export { publicEndpoints };

export default API;
