import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/user';
import otherReducer from './slices/other';
import list_all_vehicle_reducer from './slices/usedcarSlice';

export const store = configureStore({
    reducer: {
        user: userReducer,
        other: otherReducer,
        list_used_cars: list_all_vehicle_reducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
