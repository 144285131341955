import React, { lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { AUTH, ERROR, PRIVATE, PUBLIC } from "./routes";

import Loader from "../components/loader";
import PrivateLayout from "../layouts/private.layout";
import { useAppSelector } from "../store/hooks";
import MyCarsListing from "../pages/private/mycars/list";
import MYCARDETAILS from "../pages/private/mycars/details";
import TermsAndConditions from "../pages/public/termsAndConditions";

const AuthLayout = lazy(() => import("../layouts/auth.layout"));
const PublicLayout = lazy(() => import("../layouts/public.layout"));

const LandingPage = lazy(() => import("../pages/public/landing"));
const NewLandingPage = lazy(() => import("../pages/public/new_landing"));
const AboutUs = lazy(() => import("../pages/public/about_us"));
const ContactUs = lazy(() => import("../pages/public/contact-us"));
const PrivacyPolicy = lazy(() => import("../pages/public/privacy_policy"));
const CarBuy = lazy(() => import("../pages/public/car_buy"));

const LoginPage = lazy(() => import("../pages/auth/login"));
const RegisterPage = lazy(() => import("../pages/auth/register"));
const PasswordPage = lazy(() => import("../pages/auth/password"));
const OtpPage = lazy(() => import("../pages/auth/otp"));

const MyBid = lazy(() => import("../pages/private/my-bid"));
const AuctionList = lazy(() => import("../pages/private/auction/list"));
const AuctionPricing = lazy(() => import("../pages/private/auction/pricing"));
const AuctionDetails = lazy(() => import("../pages/private/auction/details"));
const AuctionChecklist = lazy(
  () => import("../pages/private/auction/checklist")
);

const UsedCars = lazy(() => import("../pages/private/used-cars/list"));
const UsedCarDetails = lazy(() => import("../pages/private/used-cars/details"));
const AddVehicle = lazy(() => import("../pages/private/add-vehicle"));
const UserEnquiry = lazy(() => import("../pages/private/enquiry"));
const Insurance = lazy(() => import("../pages/public/services/Insurance"));
const VehicleValuationServices = lazy(
  () => import("../pages/public/services/vehicleValuationServices")
);
const FINANCE = lazy(() => import("../pages/public/services/Finance"));
const BUYER_GUIDANCE = lazy(
  () => import("../pages/public/services/buyer_guidance")
);
const VehicleDocument = lazy(
  () => import("../pages/public/services/vehicle_document")
);

const EditProfile = lazy(() => import("../pages/private/profile/edit"));

const ErrorPage = lazy(() => import("../pages/common/Error"));

const MainRoute: React.FC<any> = () => {
  const { isAppInitialized } = useAppSelector((state: any) => state.user);

  if (!isAppInitialized) {
    return <Loader />;
  }

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {/* Public Routes */}
        <Route path={PUBLIC.BASE_PATH} element={<PublicLayout />} >
        <Route
            path={PUBLIC.PAGES.NEWLANDING}
            element={
              <Suspense fallback={<Loader />}>
                <NewLandingPage />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.LANDING}
            element={
              <Suspense fallback={<Loader />}>
                <LandingPage />
              </Suspense>
            }
          />         
          <Route
            path={PUBLIC.PAGES.ABOUT_US}
            element={
              <Suspense fallback={<Loader />}>
                <AboutUs />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.TERMS_AND_CONDITIONS}
            element={
              <Suspense fallback={<Loader />}>
                <TermsAndConditions />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.PRIVACY_POLICY}
            element={
              <Suspense fallback={<Loader />}>
                <PrivacyPolicy />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.CONTACT_US}
            element={
              <Suspense fallback={<Loader />}>
                <ContactUs />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.CAR_BUY}
            element={
              <Suspense fallback={<Loader />}>
                <CarBuy />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.USED_CARS}
            element={
              <Suspense fallback={<Loader />}>
                <UsedCars />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.INSURANCE}
            element={
              <Suspense fallback={<Loader />}>
                <Insurance />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.VEHICLEVALUATIONSERVICES}
            element={
              <Suspense fallback={<Loader />}>
                <VehicleValuationServices />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.FINANCE}
            element={
              <Suspense fallback={<Loader />}>
                <FINANCE />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.BUYER_GUIDANCE}
            element={
              <Suspense fallback={<Loader />}>
                <BUYER_GUIDANCE />
              </Suspense>
            }
          />
          <Route
            path={PUBLIC.PAGES.VEHICLE_DOCUMENTED}
            element={
              <Suspense fallback={<Loader />}>
                <VehicleDocument />
              </Suspense>
            }
          />
        </Route>

        {/* Auth Routes */}
        <Route path={AUTH.BASE_PATH} element={<AuthLayout />}>
          <Route
            path={AUTH.PAGES.LOGIN}
            element={
              <Suspense fallback={<Loader />}>
                <LoginPage />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.REGISTER}
            element={
              <Suspense fallback={<Loader />}>
                <RegisterPage />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.PASSWORD}
            element={
              <Suspense fallback={<Loader />}>
                <PasswordPage />
              </Suspense>
            }
          />
          <Route
            path={AUTH.PAGES.OTP}
            element={
              <Suspense fallback={<Loader />}>
                <OtpPage />
              </Suspense>
            }
          />
          <Route index element={<Navigate to={AUTH.PAGES.LOGIN} />} />
        </Route>

        {/* Private Route */}
        <Route path={PRIVATE.BASE_PATH} element={<PrivateLayout />}>
          {/* Auctions */}
          <Route
            path={PRIVATE.PAGES.MY_BID}
            element={
              <Suspense fallback={<Loader />}>
                <MyBid />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.AUCTION_PRICING}
            element={
              <Suspense fallback={<Loader />}>
                <AuctionPricing />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.AUCTION_LIST}
            element={
              <Suspense fallback={<Loader />}>
                <AuctionList />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.AUCTION_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <AuctionDetails />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.AUCTION_CHECKLIST}
            element={
              <Suspense fallback={<Loader />}>
                <AuctionChecklist />
              </Suspense>
            }
          />

          {/*Buy and Sell */}

          <Route
            path={PRIVATE.PAGES.MY_CARS}
            element={
              <Suspense fallback={<Loader />}>
                <MyCarsListing />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.MY_CAR_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <MYCARDETAILS />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.USED_CAR_DETAILS}
            element={
              <Suspense fallback={<Loader />}>
                <UsedCarDetails />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.ADD_VEHICLE}
            element={
              <Suspense fallback={<Loader />}>
                <AddVehicle />
              </Suspense>
            }
          />

          {/* Profile */}
          <Route
            path={PRIVATE.PAGES.EDIT_PROFILE}
            element={
              <Suspense fallback={<Loader />}>
                <EditProfile />
              </Suspense>
            }
          />
          <Route
            path={PRIVATE.PAGES.ENQUIRY}
            element={
              <Suspense fallback={<Loader />}>
                <UserEnquiry />
              </Suspense>
            }
          />
          <Route
            index
            element={
              <Navigate to={PUBLIC.BASE_PATH + PUBLIC.PAGES.USED_CARS} />
            }
          />
        </Route>

        {/* Common Routes */}
        <Route
          path={ERROR.ERROR_403}
          element={
            <Suspense fallback={<Loader />}>
              <ErrorPage type={403} />
            </Suspense>
          }
        />
        <Route
          path={ERROR.CATCH_ALL}
          element={
            <Suspense fallback={<Loader />}>
              <ErrorPage type={404} />
            </Suspense>
          }
        />
        <Route index element={<Navigate to={PUBLIC.PAGES.NEWLANDING} />} />
      </Routes>
    </Suspense>
  );
};

export default MainRoute;
