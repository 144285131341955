import React from 'react'

const TermsAndConditions = () => {
    return (
        <div className='container'>
            <h1 className='text-center'>
                STANDARD TERMS AND CONDITIONS FOR SALE OF GOODS

                OF

                [NAME OF SELLER]
            </h1>
            <ol>
                <li>
                    DEFINITIONS.
                    <label className='' style={{ marginLeft: '30px', marginBottom: '10px' }}>
                        In this document the following words shall have the following meanings:
                    </label>
                    <ol>
                        <li>
                            "Buyer" means the organisation or person who buys Goods from the Seller;
                        </li>
                        <li>
                            "Goods" means the articles to be supplied to the Buyer the Seller;
                        </li>
                        <li>
                            "Intellectual Property Rights" means all patents, registered and unregistered designs, copyright, trade marks, know-how and all other forms of intellectual property wherever in the world enforceable;
                        </li>
                        <li>
                            "List Price" means the list of prices of the Goods maintained by the Seller as amended from time to time;
                        </li>
                        <li>
                            "Seller" means [NAME AND ADDRESS OF SELLER].
                        </li>
                    </ol>
                </li>
                <li>
                    GENERAL
                    <ol>
                        <li>
                            These Terms and Conditions shall apply to all contracts for the sale of Goods by the Seller to the Buyer to the exclusion of all other terms and conditions referred to, offered or relied on by the Buyer whether in negotiation or at any stage in the dealings between the parties, including any standard or printed terms tendered by the Buyer, unless the Buyer specifically states in writing, separately from such terms, that it wishes such terms to apply and this has been acknowledged by the Seller in writing.
                        </li>
                        <li>
                            Any variation to these Terms and Conditions (including any special terms and conditions agreed between the parties) shall be inapplicable unless agreed in writing by the Seller.
                        </li>
                    </ol>
                </li>
                <li>
                    PRICE AND PAYMENT
                    <ol>
                        <li>
                            The price shall be that in the Seller’s current List Price, or such other price as the parties may agree in writing. The price is exclusive of VAT or any other applicable costs. Carriage shall be paid for by the [BUYER OR SELLER].
                        </li>
                        <li>
                            Payment of the price and VAT and any other applicable costs shall be due within 30 days of the date of receipt of the invoice supplied by the Seller.
                        </li>
                        <li>
                            The Seller shall be entitled to charge interest on overdue invoices from the date when payment becomes due from day to day until the date of payment at a rate of [PERCENT] per annum above the base rate of the [CENTRAL BANK].
                        </li>
                        <li>

                            If payment of the price or any part thereof is not made by the due date, the Seller shall be entitled to:
                            <ol>
                                <li>
                                    require payment in advance of delivery in relation to any Goods not previously delivered;
                                </li>
                                <li>
                                    refuse to make delivery of any undelivered Goods whether ordered under the contract or not and without incurring any liability whatever to the Buyer for non-delivery or any delay in delivery;
                                </li>
                                <li>
                                    terminate the contract.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    DESCRIPTION

                    Any description given or applied to the Goods is given by way of identification only and the use of such description shall not constitute a sale by description. For the avoidance of doubt, the Buyer hereby affirms that it does not in any way rely on any description when entering into the contract.
                </li>
                <li>
                    SAMPLE

                    Where a sample of the Goods is shown to and inspected by the Buyer, the parties hereto accept that such a sample is so shown and inspected for the sole purpose of enabling the Buyer to judge for itself the quality of the bulk, and not so as to constitute a sale by sample.
                </li>
                <li>
                    DELIVERY
                    <ol>
                        <li>
                            Unless otherwise agreed in writing, delivery of the Goods shall take place at the address specified by the Buyer on the date specified by the Seller. The Buyer shall make all arrangements necessary to take delivery of the Goods whenever they are tendered for delivery.
                        </li>
                        <li>
                            The date of delivery specified by the Seller is an estimate only. Time for delivery shall not be of the essence of the contract.
                        </li>
                        <li>
                            If the Seller is unable to deliver the Goods for reasons beyond its control, then the Seller shall be entitled to place the Goods in storage until such times as delivery may be effected and the Buyer shall be liable for any expense associated with such storage.
                        </li>
                        <li>
                            The Buyer shall be entitled to replacement Goods where the Goods have been damaged during transportation. The Buyer must notify the Seller of the damage within 24 hours of delivery.
                        </li>
                    </ol>
                </li>
                <li>
                    RISK

                    Risk in the Goods shall pass to the Buyer at the moment the Goods are dispatched from the Seller`s premises. Where the Buyer chooses to collect the Goods itself, risk will pass when the Goods are entrusted to it or set aside for its collection, whichever happens first.
                </li>
                <li>
                    TITLE

                    Title in the Goods shall not pass to the Buyer until the Seller has been paid in full for the Goods.
                </li>
                <li>
                    WARRANTY
                    <ol>
                        <li>
                            Where the Goods have been manufactured by the Seller and are found to be defective, the Seller shall repair, or in its sole discretion, replace defective Goods free of charge within [WARRANTY PERIOD] from the date of delivery, subject to the following conditions:
                        </li>
                        <li>
                            the Buyer notifying the Seller in writing immediately upon the defect becoming apparent;
                        </li>
                        <li>
                            the defect being due to the faulty design, materials or workmanship of the Seller.
                        </li>
                        <li>
                            Any Goods to be repaired or replaced shall be returned to the Seller at the Buyer’s expense, if so requested by the Seller.
                        </li>
                        <li>
                            Where the Goods have been manufactured and supplied to the Seller by a third party, any warranty granted to the Seller in respect of the Goods shall be passed on to the Buyer.
                        </li>
                        <li>
                            The Seller shall be entitled in its absolute discretion to refund the price of the defective Goods in the event that such price has already been paid.
                        </li>
                        <li>
                            The remedies contained in this Clause are without prejudice to the other Terms and Conditions herein, including, but without limitation, Clauses 10 and 11 below.
                        </li>
                    </ol>
                </li>
                <li>
                    LIABILITY
                    <ol>
                        <li>
                            No liability of any nature shall be incurred or accepted by the Seller in respect of any representation made by the Seller, or on its behalf, to the Buyer, or to any party acting on its behalf, prior to the making of this contract where such representations were made or given in relation to:
                            <ol>
                                <li>
                                    the correspondence of the Goods with any description;
                                </li>
                                <li>
                                    the quality of the Goods; or
                                </li>
                                <li>
                                    the fitness of the Goods for any purpose whatsoever.
                                </li>
                            </ol>
                        </li>
                        <li>
                            No liability of any nature shall be accepted by the Seller to the Buyer in respect of any express term of this contract where such term relates in any way to:
                            <ol>
                                <li>
                                    the correspondence of the Goods with any description;
                                </li>
                                <li>
                                    the quality of the Goods; or
                                </li>
                                <li>
                                    the fitness of the Goods for any purpose whatsoever.
                                </li>
                            </ol>
                        </li>
                        <li>
                            All implied terms, conditions or warranties as to the correspondence of the Goods to any description or the satisfactory quality of the Goods or the fitness of the Goods for any purpose whatsoever (whether made known to the Seller or not) are hereby excluded from the contract.
                        </li>
                    </ol >
                </li >
                <li>
                    LIMITATION OF LIABILITY
                    <ol>
                        <li>
                            Where any court or arbitrator determines that any part of Clause 10 above is, for whatever reason, unenforceable, the Seller shall be liable for all loss or damage suffered by the Buyer but in an amount not exceeding the contract price.
                        </li>
                        <li>
                            Nothing contained in these Terms and Conditions shall be construed so as to limit or exclude the liability of the Seller for death or personal injury as a result of the Seller’s negligence or that of its employees or agents.
                        </li>
                    </ol>
                </li>
                <li>
                    INTELLECTUAL PROPERTY RIGHTS.
                </li>
                All Intellectual Property Rights produced from or arising as a result of the performance of this Agreement shall, so far as not already vested, become the absolute property of the Seller, and the Buyer shall do all that is reasonably necessary to ensure that such rights vest in the Seller by the execution of appropriate instruments or the making of agreements with third parties.
                <li>
                    FORCE MAJEURE
                </li>
                The Seller shall not be liable for any delay or failure to perform any of its obligations if the delay or failure results from events or circumstances outside its reasonable control, including but not limited to acts of God, strikes, lock outs, accidents, war, fire, breakdown of plant or machinery or shortage or unavailability of raw materials from a natural source of supply, and the Seller shall be entitled to a reasonable extension of its obligations. If the delay persists for such time as the Seller considers unreasonable, it may, without liability on its part, terminate the contract.
                <li>
                    RELATIONSHIP OF PARTIES
                </li>
                Nothing contained in these Terms and Conditions shall be construed as establishing or implying any partnership or joint venture between the parties and nothing in these Terms and Conditions shall be deemed to construe either of the parties as the agent of the other.
                <li>
                    ASSIGNMENT AND SUB-CONTRACTING
                </li>
                The contract between the Buyer and Seller for the sale of Goods shall not be assigned or transferred, nor the performance of any obligation sub-contracted, in either case by the Buyer, without the prior written consent of the Seller.
                <li>
                    WAIVER
                </li>
                The failure by either party to enforce at any time or for any period any one or more of the Terms and Conditions herein shall not be a waiver of them or of the right at any time subsequently to enforce all Terms and Conditions of this Agreement.
                <li>
                    SEVERABILITY
                </li>
                If any term or provision of these Terms and Conditions is held invalid, illegal or unenforceable for any reason by any court of competent jurisdiction such provision shall be severed and the remainder of the provisions hereof shall continue in full force and effect as if these Terms and Conditions had been agreed with the invalid, illegal or unenforceable provision eliminated.
                <li>
                    GOVERNING LAW AND JURISDICTION
                </li>
                This Agreement shall be governed by and construed in accordance with the law of [COUNTRY] and the parties hereby submit to the exclusive jurisdiction of the [NATIONALITY] courts.
            </ol>
        </div >
    )
}

export default TermsAndConditions