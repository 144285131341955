import React, { useState } from "react";
import { Tab, Tabs, Nav } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

const DetailTabs: React.FC<any> = (props: any) => {
  const { features } = props;
  const [key, setKey] = useState("options");
  return (
      <div className="auction-dtls-bx mb-4">
          <Tabs
              id="controlled-tab-example"
              activeKey={key}
              onSelect={(k: any) => setKey(k)}
              className="nav-pills mb-3 mt-3"
          >
              <Tab
                  eventKey="options"
                  title=" Options & features"
                  tabClassName="nav-item mb-3"
              >
                  <div
                      className="tab-pane"
                      id="pills-feature"
                      role="tabpanel"
                      aria-labelledby="pills-feature-tab"
                  >
                      <Accordion>
                          {' '}
                          {features &&
                              features?.length > 0 &&
                              features.map((item: any, index: number) => (
                                  <Accordion.Item eventKey={index.toString()}>
                                      <Accordion.Header>
                                          {' '}
                                          {item?.feature_name}
                                      </Accordion.Header>
                                      <Accordion.Body>
                                          {item?.details || 'N/A'}
                                      </Accordion.Body>
                                  </Accordion.Item>
                              ))}
                      </Accordion>
                  </div>
              </Tab>
              <Tab eventKey="specs" title="Specifications">
                  <div
                      className="tab-pane "
                      id="pills-reviews"
                      role="tabpanel"
                      aria-labelledby="pills-reviews-tab"
                  >
                      ...
                  </div>
              </Tab>
              <Tab eventKey="ratings" title="Rating & reviews">
                  <div
                      className="tab-pane"
                      id="pills-reviews"
                      role="tabpanel"
                      aria-labelledby="pills-reviews-tab"
                  >
                      ...
                  </div>
              </Tab>
          </Tabs>
      </div>
  );
};

export default DetailTabs;
