import React from 'react';
import numberToWords from 'number-to-words';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { titleCase } from '../../../../utils/functions';
import DetailTabs from './tabs';
import More from './more';
import petrolImg from '../../../../assets/images/icons/Petrol.svg';
import fuelImg from '../../../../assets/images/icons/Fuel.svg';
import warrantyImg from '../../../../assets/images/icons/Warranty icon.svg';
import useMyCarDetail from './useMyCarDetail';

const NextArrow = (props: any) => {
    return (
        <button
            {...props}
            className="carousel-control-next"
            data-bs-slide="next"
            data-bs-target="#carouselExampleCaptions"
            type="button"
        >
            <span
                aria-hidden="true"
                className="carousel-control-next-icon"
            ></span>{' '}
            <span className="visually-hidden">Next</span>
        </button>
    );
};
const PrevArrow = (props: any) => {
    return (
        <button
            {...props}
            className="carousel-control-prev"
            data-bs-slide="prev"
            data-bs-target="#carouselExampleCaptions"
            type="button"
        >
            <span
                aria-hidden="true"
                className="carousel-control-prev-icon"
            ></span>{' '}
            <span className="visually-hidden">Previous</span>
        </button>
    );
};

const MYCARDETAILS = () => {
    const { vehicleDetails, vehicleFeatures, vehicleImages, id, markAsSold } =
    useMyCarDetail();
    var settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 3000,
        speed: 500,
        slidesToShow: 1.0,
        slidesToScroll: 1,
        className: 'auction-details-slick',
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,

        customPaging: (i: any) => (
            <Link to="#" className="cursor-pointer active">
                <img
                    src={
                        vehicleImages &&
                        `https://dev.admin.carsinindia.in/storage/app/public/uploads/buySellImages/${id}/${vehicleImages[i].image}`
                    }
                    style={{ height: '88px', width: '147px' }}
                    alt="car"
                />
            </Link>
        ),
        dotsClass: 'carousel-indicators',
    };
    return (
        <>
            <section className="inner-page gin-t auction-dtls">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-7">
                            {/* <!-- carousel starts--> */}
                            <div className="carousel-inner">
                                <Slider {...settings}>
                                    {vehicleImages &&
                                        vehicleImages?.map((item: any) => (
                                            <div>
                                                <img
                                                    src={`https://dev.admin.carsinindia.in/storage/app/public/uploads/buySellImages/${id}/${item.image}`}
                                                    className="w-100  img-fluid"
                                                    style={{
                                                        height: '300px',
                                                    }}
                                                    alt="car"
                                                />
                                            </div>
                                        ))}
                                </Slider>

                                {/* <Countdown
                                endDate={}
                                parentContainerClass="auction-dtls-date"
                            /> */}
                            </div>
                            {/* <!-- carousel ends--> */}

                            <div className="clearfix"></div>
                            <div className="specification-bx">
                                <div className="row mb-3">
                                    <div className="col-sm-6 col-6">
                                        <div className="row">
                                            <div className="col-sm-2 col-2">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <img
                                                        src={petrolImg}
                                                        className="img-fluid"
                                                        alt="car"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-10 col-10 pl-0">
                                                Fuel type
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        {vehicleDetails?.vehicle_fuel}
                                    </div>
                                </div>
                                {/* <div className="row mb-3">
                                <div className="col-sm-6 col-6">
                                    <div className="row">
                                        <div className="col-sm-2 col-2">
                                            <div className="d-flex justify-content-center align-items-center">
                                                <img
                                                    src={fuelImg}
                                                    className="img-fluid"
                                                    alt="car"
                                                />
                                            </div>
                                        </div>
                                        <div className="col-sm-10 col-10 pl-0">
                                            Mileage
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-6 col-6">
                                    20.71 - 22 km/l
                                </div>
                            </div> */}
                                <div className="row">
                                    <div className="col-sm-6 col-6">
                                        <div className="row">
                                            <div className="col-sm-2 col-2">
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <img
                                                        src={warrantyImg}
                                                        className="img-fluid"
                                                        alt="car"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-sm-10 col-10 pl-0">
                                                Warranty
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-6">
                                        5 years
                                    </div>
                                </div>
                            </div>

                            {/* <!-- description starts--> */}
                            <div className="auction-dtls-bx mt-5 mb-4">
                                <div className="auction-dtls-bx-title">
                                    Product Description
                                </div>
                                <p>{vehicleDetails?.description || 'N/A'}</p>
                            </div>

                            <DetailTabs
                            features={vehicleFeatures}
                            />

                            <More
                            price={vehicleDetails?.price}
                            />
                        </div>
                        <div className="col-sm-5">
                            <div className="pdtls">
                                <span className="list-bx-status">Used</span>
                                <div className="list-bx-title pdtls-title mt-2">
                                    {/* Skoda Octavia AMBIENTE 1.9 TDI 2008 */}
                                    {`${vehicleDetails?.vehicle_make || ''} ${
                                        vehicleDetails?.vehicle_model || ''
                                    }
                                     ${
                                         vehicleDetails?.vehicle_variant || ''
                                     }` || 'N/A'}
                                </div>
                                <small>
                                    {vehicleDetails?.kilometers || 'N/A'} Km,{' '}
                                    {vehicleDetails?.vehicle_fuel || ''},{' '}
                                    {titleCase(
                                        numberToWords.toWordsOrdinal(
                                            vehicleDetails?.vehicle_ownership_number ||
                                                0
                                        )
                                    )}{' '}
                                    Owner, {vehicleDetails?.location},
                                </small>
                                <small>{' '}{vehicleDetails?.vehicle_year} Registration</small>
                                <div className="list-bx-price pdtls-price mt-2">
                                    <span>Price </span>₹ {vehicleDetails?.price}
                                </div>
                                <button
                                    className="btn btn-lg blue-btn rounded-0 com-btn mt-4 mb-3"
                                    onClick={markAsSold}
                                >
                                    Mark As Sold
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default MYCARDETAILS;
