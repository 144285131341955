import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import API from '../../../../config/api';
import { PRIVATE } from '../../../../routes/routes';
import service from '../../../../utils/service';

const useMyCarDetail = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [vehicleDetails, setVehicleDetails] = useState<any>({});
    const [vehicleFeatures, setVehicleFeatures] = useState<any[]>([]);
    const [vehicleImages, setVehicleImages] = useState<any[]>([]);
    useEffect(() => {
        getMyCarDetail();
        return () => {};
    }, []);

    const getMyCarDetail = async () => {
        const { data } = await service.post(API.GET_USED_CAR_DETAIL, {
            vehicle_id: id,
        });
        if (data.status_code == 200) {
            setVehicleDetails(data.data.vehicleDetails);
            setVehicleFeatures(data.data.vehicleFeatures);
            setVehicleImages(data.data.vehicleImages);
        }
    };

    const markAsSold = async () => {
        try {
            const { data } = await service.post(API.MARK_AS_SOLD, {
                vehicle_id: id,
            });
            console.log(data);
            if (data.status_code === 200) {
                toast.success(data.message);
                setTimeout(() => {
                    navigate(PRIVATE.BASE_PATH + '/' + PRIVATE.PAGES.MY_CARS);
                }, 2000);
            } else if (data.errors) {
                toast.error(data.error.message || 'Something went wrong.');
            }
        } catch (error: any) {
            toast.error(error);
        }
    };

    return {
        vehicleDetails,
        vehicleFeatures,
        vehicleImages,
        id,
        markAsSold,
    };
};

export default useMyCarDetail;
