// import Config from 'react-native-config';
// import {getUniqueId} from 'react-native-device-info';
// import EncryptedStorage from 'react-native-encrypted-storage';
// import messaging from '@react-native-firebase/messaging';

// import service from './service';

// export const getFcmToken = async () => {
//   if (!messaging().isDeviceRegisteredForRemoteMessages) {
//     messaging().registerDeviceForRemoteMessages();
//   }
//   const fcmToken = await messaging().getToken();
//   if (fcmToken) {
//     EncryptedStorage.setItem('fcm', fcmToken);
//   } else {
//   }
//   return;
// };

// export async function registerToken() {
//   try {
//     let uniqueId = getUniqueId();
//     const token = await EncryptedStorage.getItem('fcm');
//     let data = new FormData();
//     data.append('device_id', uniqueId);
//     data.append('token', token);
//     await service.post(Config.POST_FCM, data, {
//       headers: {
//         'Content-Type': 'multipart/form-data',
//       },
//       transformRequest: formData => formData,
//     });
//   } catch (err) {}
// }

export async function registerToken() {}
export const getFcmToken = async () => {};
